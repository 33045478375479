import React from "react"
import { graphql } from "gatsby"
// import HomeTallersPage from "../components/index/tallers"
import Layout from "../components/layout"
import Img from "gatsby-image"
import formatRichText from "../utils/richText"
import SEO from "../utils/seo"

const Projecte = ({ data }) => {
  const descripcioProjecte = formatRichText(data.projecte.contingut.json)
  const descripcioPedagogia = formatRichText(data.pedagogica.contingut.json)
  const personal = data.allContentfulPersonal.edges
  return (
    <Layout title={data.projecte.titol}>
      <SEO title={data.projecte.titol} />
      <div className="container" style={{ marginTop: 20 }}>
        <div className="row">
          <div className="col2 column">
            <br />
            <div className="descripcio">{descripcioProjecte}</div>
          </div>
          <div className="col2 column">
            <iframe
              title="projecte"
              className="youtube"
              width="800"
              height="400"
              src="https://www.youtube.com/embed/KsAk5QR1ewo"
              frameborder="0"
              allowfullscreen="allowfullscreen"
            />
          </div>
        </div>
        <br />
        <h1>{data.pedagogica.titol}</h1>
        <br />
        <div className="descripcio">{descripcioPedagogia}</div>
        <br />
        <h1>El nostre equip</h1>

        <section id="portfolio" className="portfolio">
          <div className="tallers row">
            {personal.map(({ node: membre }) => (
              <div key={membre.id} className="column column-fit col4">
                <div
                  className="portfolio-item dark  portfolio-item-has-excerpt"
                  style={{ color: "white !important" }}
                >
                  <div
                    className="portfolio-item-overlay"
                    style={{ backgroundColor: "darkgrey" }}
                  />
                  <div className="portfolio-item-description">
                    <h3 className="team-item-title">{membre.nom}</h3>
                    <br />
                    <p>{membre.extracte}</p>
                  </div>
                  <Img fixed={membre.foto.fixed} alt={membre.foto.title} />
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
    </Layout>
  )
}
export default Projecte
export const pageQuery = graphql`
  query {
    projecte: contentfulPaginaEstatica(slug: { eq: "equip" }) {
      id
      titol
      slug
      contingut {
        json
      }
    }
    pedagogica: contentfulPaginaEstatica(slug: { eq: "linia-pedagogica" }) {
      id
      titol
      slug
      contingut {
        json
      }
    }
    allContentfulPersonal(limit: 100, sort: { fields: ordre }) {
      edges {
        node {
          id
          nom
          extracte
          foto {
            title
            fixed(width: 300, height: 300) {
              ...GatsbyContentfulFixed
            }
          }
        }
      }
    }
  }
`
